import React from 'react';
import { useAuthenticationState } from '../../context/Auth.context';
import BasicTable from './BasicTable';
import TableTab from './TableTab';
import endpoints from '../../endpoints';

const EventsAndAnnouncements = () => {
    const { user } = useAuthenticationState();

    const eventColumns = [
        { Header: 'Event Name', accessor: 'Event_CALENDAR_EVENT_NAME' },
        { Header: 'Date', accessor: 'date' },
        { Header: 'Time', accessor: 'time' },
        { Header: 'Location', accessor: 'Event_Location' },
        { Header: 'RSVP', accessor: 'rsvp' },
    ];

    const announcementsColumns = [
        { Header: 'Title', accessor: 'Alumni_AnnouncmentReq_TITLE' },
        { Header: 'Type', accessor: 'Alumni_AnnouncmentReq_TYPE' },
        { Header: 'Priority', accessor: 'Alumni_AnnouncmentReq_PRIORITY' },
        { Header: 'Expiry', accessor: 'expiryDate' },
        { Header: 'Owner', accessor: 'ALUMNI_Name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Location', accessor: 'Alumni_AnnouncmentReq_Location' },
    ];

    const tabs = [
        {
            label: 'Events',
            component: (props) => (
                <BasicTable
                    columns={eventColumns}
                    endpoint={endpoints.eventCalendar.studentEventsList}
                    name='events'
                    {...props}
                />
            ),
            filterOptions: [
                { value: 'current', label: 'This Week' },
                { value: 'upcoming', label: 'Upcoming Week' },
                { value: 'past', label: 'Past Week' },
            ],
        },
        {
            label: 'Announcements',
            component: (props) => (
                <BasicTable
                    columns={announcementsColumns}
                    name={'announcements'}
                    endpoint={endpoints.classRepDashboard.announcementsList}
                    {...props}
                />
            ),
            filterOptions: [
                { value: 'current', label: 'This Week' },
                { value: 'upcoming', label: 'Upcoming Week' },
                { value: 'past', label: 'Expired' },
            ],
        },
    ];

    return (
        <>
            <TableTab tabs={tabs} />
        </>
    );
};

export default EventsAndAnnouncements;
