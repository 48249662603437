import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import AlumniJobDomain from './AlumniJobDomain';
import Chip from './Chip';
import Entrepreneurs from './EntreInd';
import CityChapterInclusion from './CityChapterInclusion';
import Loader from '../../components/Loader';
import EventsAndAnnouncements from './EventsAndAnnouncements';

function Index() {
    const { response, loading } = useAxiosGetMultiple(
        {
            basics: endpoints.classRepDashboard.overView,
            alumniJobIndustry: endpoints.statistics.alumniJobIndustry,
            alumniJobOrg: endpoints.statistics.alumniJobOrg,
            entreByIndustry: endpoints.statistics.entreByIndustry,
            cityChapterInclusion: endpoints.statistics.cityChapterInclusion,
            activeEntrepreneurs:
                endpoints.classRepDashboard.activeEntrepreneurs,
        }
        // { batch: false }
    );

    const { basics, alumniJobIndustry, alumniJobOrg, activeEntrepreneurs } =
        response;

    const cityChapterInclusion = response.cityChapterInclusion?.filter(
        (data) => !!data.COUNT_ACTIVE_ALUMNI
    );
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip
                                bg='#FAEDC6'
                                title='Student Council Count'
                            >
                                {basics.studentCouncilCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#DADDFC'
                                title='Total Student Registered'
                            >
                                {basics.totalStudentsCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#CEE5D0'
                                title='No. of Entrepreneurs'
                            >
                                {basics.entrepreneurCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#FAEDC6'
                                title='Student looking for job'
                            >
                                {basics.studentLookingForJobCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#DADDFC'
                                title='Active help requests'
                            >
                                {basics.activeHelpRequest ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#CEE5D0'
                                title='Active Mark as Safe events'
                            >
                                {basics.activeMarkAsSafe ?? 0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col
                    md='4'
                    style={{ marginTop: '-3%' }}
                >
                    {' '}
                    <Entrepreneurs data={activeEntrepreneurs} />{' '}
                </Col>

                <Col
                    md='8'
                    className='gy-5'
                // style={{ marginTop: '-4%' }}
                >
                    <AlumniJobDomain
                        data={{
                            industry: alumniJobIndustry,
                            organization: alumniJobOrg,
                        }}
                    />
                </Col>
                <Col
                    md='4'
                    className='gy-5'
                >
                    <CityChapterInclusion data={cityChapterInclusion || []} />{' '}
                </Col>
                <Col className='gy-5'>
                    <EventsAndAnnouncements />
                </Col>
            </Row>
        </>
    );
}

export default Index;
