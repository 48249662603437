import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import StudentForm from './FacultyForm';
import viewProps from '../viewProps';
import BulkUpload from '../../components/BulkUpload';
import { useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import errorObjects from './facultyErrorObjects';

function StudentDetails() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(endpoints.alumniDetail.getFacultyOnly);

    const { state } = useLocation();

    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const options = { month: 'long', day: 'numeric' };
    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        Type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
    };
    const today = new Date().getFullYear();
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniDetail}
                Form={StudentForm}
                formSize='lg'
                {...viewProps.FacultyDetails}
                cellModifier={cellModifier}
                {...fetchUtils}
                headerExtras={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role) && (
                        <BulkUpload
                            urlObjects={{
                                templateUrl: endpoints.alumniDetail.bulkUploadTemplate,
                                fileUpload: endpoints.alumniBulkUpload.fileUpload,
                                validateFile: endpoints.alumniBulkUpload.validateFile,
                                initialBulkInsert: endpoints.alumniBulkUpload.initialBulkInsert,
                                bulkUploadConfirm: endpoints.alumniBulkUpload.bulkUploadConfirm,
                            }}
                            filename='Faculty Details'
                            afterUpload={fetchUtils.reFetch}
                            templateExtension='.xlsx'
                            errorObjects={errorObjects}
                        />
                    )
                }
                initialFilterValue={state || ''}
                irremovable
                immutable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                insertable={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                        // 'delegate + cch',
                        'DELEGATE',
                    ].includes(user.Role)
                }
            />
        </>
    );
}

export default StudentDetails;
