import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BulkUpload from '../../components/BulkUpload';
import { useLocation } from 'react-router-dom';
import InstituteMemberForm from './InstituteMemberForm';
import { useAuthenticationState } from '../../context/Auth.context';

function InstituteMemberDetails() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(
        endpoints.alumniDetail.getInstituteMemberOnly
    );

    const { state } = useLocation();

    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const cellModifier = {
        DateOfBirth: ({ value }) => new Date(value).toDateString(),
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        Type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
                6: 'Institute Member',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.instituteMemberDetail}
                Form={InstituteMemberForm}
                formSize='lg'
                {...viewProps.InstituteMemberDetails}
                cellModifier={cellModifier}
                {...fetchUtils}
                headerExtras={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role) && (
                        <BulkUpload
                            uploadUrl={endpoints.alumniDetail.bulkUpload}
                            templateUrl={
                                endpoints.alumniDetail.bulkUploadTemplate
                            }
                            filename='Institute Member Details'
                        />
                    )
                }
                initialFilterValue={state || ''}
                irremovable
                immutable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                    'DELEGATE + CCH',
                    'DELEGATE',
                ].includes(user.Role)}
                insertable={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                        'DELEGATE + CCH',
                        'DELEGATE',
                    ].includes(user.Role)
                }
                // insertable = {false}
            />
        </>
    );
}

export default InstituteMemberDetails;
