import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from './TableLoader';

const BasicTable = ({ columns, endpoint, name, filterOption }) => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(
                    `${endpoint}?page=${currentPage}&limit=${rowsPerPage}`
                );
                setDetails(response.data.responseData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching Details:', error);
                setLoading(false);
            }
        };

        fetchDetails();
    }, [currentPage, name]);

    useEffect(() => {
        setCurrentPage(1);
    }, [name]);

    const handleChangePage = (e) => {
        setCurrentPage(parseInt(e.target.value));
    };

    const formatDateTime = (dateString) => {
        const eventDate = new Date(dateString);
        const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const formattedDate = eventDate.toLocaleDateString(
            'en-GB',
            dateOptions
        );
        const formattedTime = eventDate.toLocaleTimeString(
            'en-US',
            timeOptions
        );

        return (
            <>
                {formattedDate}
                <br />
                {formattedTime}
            </>
        );
    };

    const data = details.map((item) => {
        if (name === 'events') {
            const eventDate = new Date(item.Event_CALENDAR_SCHEDULE_STARTDATE);
            const eventTime = new Date(item.Event_CALENDAR_SCHEDULE_STARTTIME);

            const dateOptions = {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            };
            const timeFormatOptions = {
                hour: '2-digit',
                minute: '2-digit',
            };

            const formattedDate = eventDate.toLocaleDateString(
                'en-GB',
                dateOptions
            );
            const formattedTime = eventTime.toLocaleTimeString(
                'en-US',
                timeFormatOptions
            );

            return {
                ...item,
                date: formattedDate,
                time: formattedTime,
                rsvp: (
                    <Link
                        to='/rsvp'
                        state={item}
                        style={{ color: '#2E2E2E', fontSize: '14px' }}
                    >
                        <div style={{ textDecoration: 'underline' }}>
                            {' '}
                            View RSVP Details
                        </div>
                    </Link>
                ),
            };
        } else {
            const formattedDateTime = formatDateTime(
                item.Alumni_AnnouncmentReq_ExpiryDate
            );
            return {
                ...item,
                expiryDate: formattedDateTime,
                email: item.PERSONAL_EMAILID
                    ? item.PERSONAL_EMAILID
                    : item.Alumni_AnnouncmentReq_AlternateEmail,
            };
        }
    });

    const getWeekStartEndDates = (currentDate = new Date()) => {
        const startOfWeek = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
        );
        const endOfWeek = new Date(
            currentDate.setDate(
                currentDate.getDate() - currentDate.getDay() + 6
            )
        );
        return { startOfWeek, endOfWeek };
    };

    const { startOfWeek, endOfWeek } = getWeekStartEndDates();

    const filteredData = data.filter((item) => {
        const eventDate = item.expiryDate
            ? new Date(item.Alumni_AnnouncmentReq_ExpiryDate)
            : new Date(item.Event_CALENDAR_SCHEDULE_STARTDATE);
        switch (filterOption) {
            case 'current':
                return eventDate >= startOfWeek && eventDate <= endOfWeek;
            case 'upcoming':
                return eventDate > endOfWeek;
            case 'past':
                return eventDate < startOfWeek;
            default:
                return true;
        }
    });

    if (loading) {
        return <Loader />;
    }

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    const options = [];
    for (let i = 1; i <= totalPages; i++) {
        options.push(
            <option
                key={i}
                value={i}
            >
                {i}
            </option>
        );
    }

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentData = filteredData.slice(startIndex, endIndex);

    return (
        <>
            {currentData.length === 0 ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            fontSize: '18px',
                            fontWeight: '600',
                            textAlign: 'center',
                            padding: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        No Result Found
                    </div>
                </>
            ) : (
                <>
                    <div
                        id='table-container'
                        style={{
                            border: '1px solid #E3E3E3',
                            borderRadius: '10px',
                            overflow: 'hidden',
                        }}
                    >
                        <table
                            style={{
                                border: '1px solid #E3E3E3',
                                width: '100%',
                                borderCollapse: 'collapse',
                                borderRadius: '10px',
                            }}
                        >
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th
                                            key={column.accessor}
                                            style={{
                                                border: '1px solid #E3E3E3',
                                                padding: '8px',
                                                backgroundColor: '#5FB3F6',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {column.Header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {columns.map((column, index) => (
                                            <td
                                                key={column.accessor}
                                                style={{
                                                    border: '1px solid #E3E3E3',
                                                    padding: '8px',
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    color: '#2E2E2E',
                                                    maxWidth: '40px',
                                                    overflow: 'auto',
                                                    fontWeight:
                                                        index === 0 && 600,
                                                }}
                                            >
                                                {row[column.accessor]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div
                        style={{
                            marginTop: '10px',
                            textAlign: 'center',
                            color: '#0096FF',
                        }}
                    >
                        <label htmlFor='pageSelect'>Page</label>
                        <select
                            id='pageSelect'
                            value={currentPage}
                            onChange={handleChangePage}
                            style={{
                                marginLeft: '5px',
                                color: '#0096FF',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                appearance: 'none',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            {options}
                        </select>
                        <span style={{ marginLeft: '10px' }}>
                            of {totalPages}
                        </span>
                    </div>
                </>
            )}
        </>
    );
};

export default BasicTable;
