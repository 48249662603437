import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';

function MarkAsSafeTable() {
    const { user } = useAuthenticationState();
    let getApi;
    const getRoleCategory = (role) => {
        if (['ADMIN'].includes(role)) {
            return 'alumni';
        } else if (
            [
                'DELEGATE',
                'DELEGATE+CCH',
                //  'CLASSREPRESENTATIVE',
                //  'CLASSREPRESENTATIVE+STUDENTCOUNCIL',
            ].includes(role)
        ) {
            return 'student';
        } else {
            return 'others';
        }
    };

    switch (getRoleCategory(user.Role)) {
        case 'alumni':
            getApi = endpoints.adminMarkAsSafe.get;

            break;
        case 'student':
            getApi = endpoints.markAsSafe.get;

            break;
        default:
            getApi = endpoints.adminMarkAsSafe.get;

            break;
    }
    const fetchUtils = useAxiosGet(getApi);

    const cellModifier = {
        // Response: ({ row }) => (
        //     <Link
        //         to={'/safety-response'}
        //         state={row.original.Alumni_MarkAsSafe_EventID}
        //     >
        //         User Responses
        //     </Link>
        // ),
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
    };
    return (
        <>
            <Table
                {...viewProps.MarkAsSafenewdb}
                cellModifier={cellModifier}
                addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
            />
        </>
    );
}

export default MarkAsSafeTable;
