import React from 'react';
import Avatar from '../../asset/img/Avatar.png';
import bgImg from '../../asset/img/ProfileBackground.png';
import { ReactComponent as EntreTagIcon } from '../../asset/icons/EntreTag.svg';
import ProfileModal from './ProfileModal';

const EntrepreneurRow = ({ data }) => {
    const experience = data?.YearsOfexperience / 12;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                    <img
                        src={data?.ALUMNI_IMAGE_URL || Avatar}
                        width={40}
                        className='rounded-circle shadow-sm'
                        alt={data?.ALUMNI_Name}
                    />
                </span>
                <div style={{ marginLeft: '10px', color: '#4C4C4C' }}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>
                        {data?.ALUMNI_Name}
                    </div>
                    <div style={{ fontSize: '14px' }}>{data?.Industry}</div>
                </div>
            </div>
            <div>{data?.Alumni_Current_Location}</div>
            <div>
                <ProfileModal
                    renderModalBody={() => (
                        <div style={{ borderRadius: '32px' }}>
                            <div
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={bgImg}
                                    width='100%'
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '76px',
                                    left: '25px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '95%',
                                }}
                            >
                                <div
                                    style={{
                                        padding: '5px',
                                        borderRadius: '50%',
                                        background: '#fff',
                                    }}
                                >
                                    <img
                                        className='rounded-circle shadow-sm'
                                        src={data?.ALUMNI_IMAGE_URL || Avatar}
                                        width={100}
                                    />
                                </div>
                                <div>
                                    <EntreTag />
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: '50px',
                                    padding: '15px 37px 15px 37px',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        color: '#4C4C4C',
                                    }}
                                >
                                    {data?.ALUMNI_Name}
                                </div>
                                <div
                                    style={{
                                        fontSize: '16px',
                                        color: '#4C4C4C',
                                    }}
                                >
                                    {data?.Alumni_Current_Location}
                                </div>
                                <div
                                    style={{
                                        color: '#467AFF',
                                        fontSize: '16px',
                                    }}
                                >
                                    {data?.PERSONAL_EMAILID}
                                </div>
                                <div
                                    style={{
                                        border: '1px solid #D9D9D9',
                                        width: '100%',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                ></div>
                                <div
                                    style={{
                                        color: '#4C4C4C',
                                        fontSize: '14px',
                                        paddingBottom: '15px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    {data?.description || (
                                        <>
                                            {data?.ALUMNI_Name} is a seasoned
                                            entrepreneur with over {experience}{' '}
                                            years of experience in the{' '}
                                            {data?.Industry} industry.
                                            {/* He is the{' '}
                                            {data?.Designation} of{' '}
                                            {data?.Organization_Name}, a company
                                            dedicated to{' '}
                                            {data?.ServicesAndProductsOffered} */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                >
                    <div
                        style={{
                            color: '#0D8D1A',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                    >
                        See Profile
                    </div>
                </ProfileModal>
            </div>
        </div>
    );
};

function EntreInd({ data }) {
    return (
        <>
            <div className='small h6 text-dark'>Active Entrepreneurs</div>

            <div className='py-4 bg-white rounded-smoother mt-3 card-shadow'>
                {data?.length === 0 ? (
                    <div className='p-5 text-center text-muted'>
                        No Entrepreneurs have been Registered so far
                    </div>
                ) : (
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {data?.map((item, index) => (
                            <EntrepreneurRow
                                data={item}
                                key={index}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default EntreInd;

function EntreTag() {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    background: '#0D8D1A',
                    color: '#fff',
                    padding: '9px 20px 9px 34px',
                    borderRadius: '20px 0px 0px 20px',
                    alignItems: 'center',
                }}
            >
                <EntreTagIcon />
                <div style={{ paddingLeft: '5px' }}>Entrepreneur</div>
            </div>
        </>
    );
}
