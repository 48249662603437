import React from 'react';

const Entrepreneur = () => {
    const contacts = [
        { name: 'John Doe', phone: '123-456-7890' },
        { name: 'Jane Smith', phone: '987-654-3210' },
        { name: 'Alice Johnson', phone: '555-666-7777' },
    ];

    const wrapperStyle = {
        margin: '0 auto',
        borderRadius: '12px',
        overflow: 'hidden',
        width: '80%',
        border: '1px solid #ddd',
    };

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thTdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    const thStyle = {
        ...thTdStyle,
        backgroundColor: '#f2f2f2',
    };

    const appStyle = {
        textAlign: 'center',
        marginTop: '20px',
    };

    return (
        <div style={appStyle}>
            <div style={wrapperStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                Name
                            </th>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                Location
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact, index) => (
                            <tr key={index}>
                                <td style={thTdStyle}>{contact.name}</td>
                                <td style={thTdStyle}>{contact.phone}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Entrepreneur;
