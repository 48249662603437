import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CityChapterForm from './CityChapterForm';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import BulkUpload from '../../components/BulkUpload';
import errorObjects from './CityChapterErrorObjects';
import MappingBulkUploadUserGuide from './MappingBulkUploadUserGuide';
import CustomToolTip from './CustomToolTip';
import DownloadExcelWithWantedHeads from '../../components/DownloadExcelWithWantedColumns';
// import ReactExport from 'react-export-excel-xlsx-fix';
// import { ReactComponent as Download } from '../../asset/icons/Download.svg';
// import { Button } from 'react-bootstrap';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CityChapters() {
    // const fetchUtils = useAxiosGet(endpoints.cityChapters.get);
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    const url = `${endpoints.cityChapters.get}/?page=${page}&limit=10`;
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    let cityChapters = [];

    if (user?.CCH?.CityChapter_ID) {
        cityChapters = fetchUtils.response.result?.filter(
            (city) => city.CityChapter_ID === user?.CCH?.CityChapter_ID
        );
    }

    const cellModifier = {
        AlumniCityChapterMap: ({ row }) => (
            <Link to={'/city-chapter-map'} state={row.original}>
                City chapter Alumni list
            </Link>
        ),
    };

    return (
        <>
            <PaginatedTable
                isCch={user?.CCH?.CityChapter_ID}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                setTriggerApiCall={setTriggerApiCall}
                endpoints={endpoints.cityChapters}
                Form={CityChapterForm}
                addBtnLabel={'Add City Chapters'}
                downloadExcelName="City Chapters"
                name={
                    user.Role === 'ADMIN' || user.Role === 'GCM' ? (
                        <div style={{ marginTop: '-30px' }}>
                            <div>{'City Chapters'}</div>
                            <MappingBulkUploadUserGuide />
                        </div>
                    ) : (
                        'City Chapters'
                    )
                }
                {...viewProps.CityChapters}
                cellModifier={cellModifier}
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                {...fetchUtils}
                response={
                    cityChapters?.length
                        ? cityChapters
                        : fetchUtils.response.result
                }
                downloadable={false}
                headerExtras={
                    <>
                        {(user.Role === 'ADMIN' || user.Role === 'GCM') && (
                            <>
                                <BulkUpload
                                    isSingleApiForUpload={true}
                                    urlObjects={{
                                        templateUrl:
                                            endpoints.cityChapterAlumniMap
                                                .bulkUploadTemplate,
                                        fileUpload:
                                            endpoints.cityChapterAlumniMap
                                                .bulkUpload,
                                        validateFile: '',
                                        initialBulkInsert: '',
                                        bulkUploadConfirm: '',
                                    }}
                                    filename="City Chapter Alumni Details"
                                    afterUpload={fetchUtils.reFetch}
                                    templateExtension=".xlsx"
                                    errorObjects={errorObjects}
                                />
                                <CustomToolTip />
                            </>
                        )}
                        <DownloadExcelWithWantedHeads
                            downloadFileName="City Chapters"
                            relevants={viewProps.CityChapters.relevants}
                            unwantedRelevant={['AlumniCityChapterMap']}
                            columnHeads={viewProps.CityChapters.columnHeads}
                            response={
                                cityChapters?.length
                                    ? cityChapters
                                    : fetchUtils.response.result
                            }
                        />
                    </>
                }
            />
        </>
    );
}

export default CityChapters;
