import React from 'react';
import { Col } from 'react-bootstrap';

function UserGroup() {
    return (
        <div>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>
                User Groups
            </span>
            <br />
            <span style={{ fontSize: '12px', fontWeight: '500' }}>
                Based on Institution
            </span>
            <Col
                className='px-2 py-3'
                style={{
                    background: '#EFEEFE',
                    borderRadius: '10px',
                }}
            >
                <Card />
                <Card />
                <Card />

                <Card />
            </Col>
        </div>
    );
}

export default UserGroup;

const Card = () => (
    <div
        style={{
            background: '#FFFFFF',
            display: 'flex',
            padding: '2%',
            borderRadius: '10px',
            marginBottom: '2%',
        }}
    >
        <div>
            <span style={{ fontSize: '14px', fontWeight: '700' }}>
                Governing Council
            </span>
            <br />
            Based on Institution
        </div>
        <div
            style={{
                marginLeft: 'auto',
                width: '70px',
                background: '#467AFF',
                height: '61px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                color: '#FFFFFF',
                fontSize: '24px',
                fontWeight: '900',
            }}
        >
            2
        </div>
    </div>
);
