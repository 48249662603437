import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import AlumniJobDomain from '../dashboard/AlumniJobDomain';
import Chip from '../dashboard/Chip';
import ECMembers from '../dashboard/ECMembers';
import EntreInd from '../dashboard/EntreInd';
import AlumniGraduated from '../dashboard/AlumniGraduated';
import CityChapterInclusion from '../dashboard/CityChapterInclusion';
import Loader from '../../components/Loader';
import Entrepreneurs from './EntrepreneurBloodDonor';
import MarkAsSafe from './MarkAsSafe';
import EventAnnouncement from './EventAnnouncement';
import HelpJobRequest from './HelpJobRequest';

function Dashboard() {
    const { response, loading } = useAxiosGetMultiple({
        // basics: endpoints.statistics.batchdashboard,
        unsafeMark: endpoints.batchRep.batchRepUnsafeMarkSafe,
        batchRepTotalHelpRequests: endpoints.batchRep.batchRepTotalHelpRequests,
        batchRepTotalNoResponses: endpoints.batchRep.batchRepTotalNoResponses,
        batchRepTotalAlumniCount: endpoints.batchRep.batchRepTotalAlumniCount,
        batchRepInactiveAlumniCount:
            endpoints.batchRep.batchRepInactiveAlumniCount,
        batchRepTotalJobRequests: endpoints.batchRep.batchRepTotalJobRequests,
        ecMembers: endpoints.statistics.entreprener,
        bloodDonor: endpoints.statistics.bloodDonor,

        // ecMembers: endpoints.statistics.enterList,
        // alumniJobIndustry: endpoints.statistics.alumniJobIndustry,
        // alumniJobOrg: endpoints.statistics.alumniJobOrg,
        // entreByIndustry: endpoints.statistics.entreByIndustry,
        // alumniPerYear: endpoints.statistics.alumniPerYear,
        // cityChapterInclusion: endpoints.statistics.cityChapterInclusion,
    });
    const {
        basics,
        ecMembers,
        unsafeMark,
        batchRepTotalHelpRequests,
        batchRepTotalNoResponses,
        batchRepTotalAlumniCount,
        batchRepInactiveAlumniCount,
        batchRepTotalJobRequests,
        bloodDonor,
        alumniJobIndustry,
        alumniJobOrg,
        alumniPerYear,
        entreByIndustry,
    } = response;
    // const cityChapterInclusion = response.cityChapterInclusion.filter(
    //     (data) => !!data.COUNT_ACTIVE_ALUMNI
    // );
    console.log(bloodDonor.alumniDetails, 'blood donor');
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Batch Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FF9A9A' title='Unsafe Response 🚨'>
                                {unsafeMark.unSafeMarkAsSafeEvents ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='No Response Count 🚫 '>
                                {batchRepTotalNoResponses.totalNoResponse ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#C6FAD8' title='Total Help Requests 🚨'>
                                {batchRepTotalHelpRequests.totalHelpRequest ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Total Alumni in Batch'>
                                {batchRepTotalAlumniCount.totalAlumniCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Inactive Alumni in Batch'>
                                {batchRepInactiveAlumniCount.inActiveAlumniCount ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Total Job Requests'>
                                {batchRepTotalJobRequests.totalJobRequestsCount ??
                                    0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <Entrepreneurs
                        entreprenerList={ecMembers}
                        bloodDonor={bloodDonor.alumniDetails}
                    />
                </Col>
                <Col md='14' className='gy-5'>
                    <MarkAsSafe />
                </Col>

                <Col md='14' className='gy-5'>
                    <EventAnnouncement />
                </Col>
                <Col md='14' className='gy-5'>
                    <HelpJobRequest />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
