import React, { useState } from 'react';
import {
    Col,
    Nav,
    Row,
    Tab,
    Dropdown,
    DropdownButton,
    ButtonGroup,
    Form,
} from 'react-bootstrap';
import EventCalendar from './EventCalender';
import endpoints from '../../endpoints';
import { useAuthenticationState } from '../../context/Auth.context';
import { useAxiosGet } from '../../hooks/axiosHooks';
import styles from './Batch.css';
import Entrepreneur from './Entrepreneur';
import BloodDonor from './BloodDonor';

function EntrepreneurBloodDonor({ data }) {
    const { user } = useAuthenticationState();
    const [filter, setFilter] = useState('all');
    const [bloodGroupFilter, setBloodGroupFilter] = useState('');
    const [userTypeFilter, setUserTypeFilter] = useState('');
    const [activeTab, setActiveTab] = useState('Entrepreneur');

    const getApi = endpoints.eventCalendar.delegate + user.DELEGATE_BATCH_CODE;
    const fetchUtils = useAxiosGet(getApi);
    const events = fetchUtils.response || [];

    const { response, ...fetchUtil } = useAxiosGet(endpoints.announcements.get);
    let announcements = [...response];

    if (['DELEGATE', 'DELEGATE + CCH'].includes(user.Role)) {
        announcements = announcements.filter(
            (ann) =>
                Number(ann.Alumni_AnnouncmentReq_BATCH_CODE) ===
                Number(user.DELEGATE_BATCH_CODE)
        );
    }

    const handleFilterChange = (eventKey) => {
        setFilter(eventKey);
        setBloodGroupFilter('');
        setUserTypeFilter('');
    };

    const handleBloodGroupFilterChange = (eventKey) => {
        setBloodGroupFilter(eventKey);
    };

    const handleUserTypeFilterChange = (eventKey) => {
        setUserTypeFilter(eventKey);
    };

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <Tab.Container
            defaultActiveKey='Entrepreneur'
            onSelect={handleTabSelect}
        >
            <Row className='px-2'>
                <Col md='10' className='w-100'>
                    <Nav
                        variant='pills'
                        className={`w-100 text-center shadow-sm ${styles.nav}`}
                    >
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='Entrepreneur'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Entrepreneurs
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='BloodDonor'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Blood Donors
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3'>
                <Tab.Pane eventKey='Entrepreneur' title='Entrepreneur'>
                    <Entrepreneur
                        filter={filter}
                        bloodGroupFilter={bloodGroupFilter}
                        userTypeFilter={userTypeFilter}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey='BloodDonor' title='BloodDonor'>
                    <Col md='2' className='text-end'>
                        {/* <DropdownButton
                            as={ButtonGroup}
                            id='dropdown-filter'
                            variant='secondary'
                            title='Filter'
                            className={styles.dropdownFilter}
                            onSelect={handleFilterChange}
                        >
                            <Dropdown
                                drop='end'
                                className={styles.customDropdown}
                            >
                                <Dropdown.Toggle
                                    variant='light'
                                    className={styles.dropdownItem}
                                >
                                    Blood Group
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className={styles.customDropdownMenu}
                                >
                                    <Dropdown.Item
                                        eventKey='O+ve'
                                        className={styles.customDropdownItem}
                                        onSelect={handleBloodGroupFilterChange}
                                    >
                                        O +ve
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey='A+ve'
                                        className={styles.customDropdownItem}
                                        onSelect={handleBloodGroupFilterChange}
                                    >
                                        A +ve
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey='AB+ve'
                                        className={styles.customDropdownItem}
                                        onSelect={handleBloodGroupFilterChange}
                                    >
                                        AB +ve
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey='B+ve'
                                        className={styles.customDropdownItem}
                                        onSelect={handleBloodGroupFilterChange}
                                    >
                                        B +ve
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey='B-ve'
                                        className={styles.customDropdownItem}
                                        onSelect={handleBloodGroupFilterChange}
                                    >
                                        B -ve
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                drop='end'
                                className={styles.customDropdown}
                            >
                                <Dropdown.Toggle
                                    variant='light'
                                    className={styles.dropdownItem}
                                >
                                    User Type
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className={styles.customDropdownMenu}
                                >
                                    <Dropdown.Item
                                        eventKey='Type1'
                                        className={styles.customDropdownItem}
                                        onSelect={handleUserTypeFilterChange}
                                    >
                                        Type 1
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey='Type2'
                                        className={styles.customDropdownItem}
                                        onSelect={handleUserTypeFilterChange}
                                    >
                                        Type 2
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </DropdownButton> */}
                    </Col>
                    <BloodDonor
                        filter={filter}
                        bloodGroupFilter={bloodGroupFilter}
                        userTypeFilter={userTypeFilter}
                    />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default EntrepreneurBloodDonor;
