import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';
// import { ReactComponent as CloseIcon } from '../../asset/icons/';

// const people = [
//     {
//         name: 'John Doe',
//         phoneNumber: '555-1234',
//         type: 'student',
//         bloodGroup: 'A+',
//         age: 20,
//     },
//     {
//         name: 'Jane Smith',
//         phoneNumber: '555-5678',
//         type: 'faculty',
//         bloodGroup: 'B-',
//         age: 45,
//     },
//     {
//         name: 'Emily Johnson',
//         phoneNumber: '555-9101',
//         type: 'alumni',
//         bloodGroup: 'O+',
//         age: 30,
//     },
//     {
//         name: 'Michael Brown',
//         phoneNumber: '555-1122',
//         type: 'student',
//         bloodGroup: 'AB+',
//         age: 22,
//     },
//     {
//         name: 'Sarah Davis',
//         phoneNumber: '555-3344',
//         type: 'faculty',
//         bloodGroup: 'A-',
//         age: 38,
//     },
// ];

// const bloodGroups = ['A+', 'B-', 'O+', 'AB+', 'A-'];
// const types = ['student', 'faculty', 'alumni'];

const BloodDonor = ({ bloodDonor }) => {
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [filterType, setFilterType] = useState(null);

    const handleBloodGroupChange = (event) => {
        setSelectedBloodGroup(event.target.value);
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const handleFilterButtonClick = () => {
        if (showFilterOptions) {
            // Reset filters when closing the filter options
            setSelectedBloodGroup(null);
            setSelectedType(null);
            setFilterType(null);
        }
        setShowFilterOptions(!showFilterOptions);
    };

    // const filteredPeople = people.filter((person) => {
    //     return (
    //         (!selectedBloodGroup || person.bloodGroup === selectedBloodGroup) &&
    //         (!selectedType || person.type === selectedType)
    //     );
    // });
    console.log(bloodDonor, 'donor');
    return (
        <div>
            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    position: 'relative',
                }}
            >
                <Button
                    onClick={handleFilterButtonClick}
                    style={{ color: 'white', border: 'none' }}
                >
                    Filter {showFilterOptions ? 'X' : <Filter />}
                </Button>
                {showFilterOptions && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '70%',
                            left: '0',
                            color: 'white',
                            zIndex: 10,
                            display: 'flex',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                    >
                        <div
                            style={{
                                marginRight: '20px',
                            }}
                        >
                            <Form
                                style={{
                                    background: '#467AFF',
                                    padding: '10px',
                                    borderRadius: '12px',
                                }}
                            >
                                <Form.Check
                                    type='radio'
                                    label='Blood Group'
                                    checked={filterType === 'bloodGroup'}
                                    onChange={() => setFilterType('bloodGroup')}
                                />
                                <Form.Check
                                    type='radio'
                                    label='User Type'
                                    checked={filterType === 'type'}
                                    onChange={() => setFilterType('type')}
                                />
                            </Form>
                        </div>
                        <div
                            style={{
                                marginRight: '20px',
                                background: '#467AFF',
                                padding: '15px',
                                borderRadius: '12px',
                            }}
                        >
                            {filterType === 'bloodGroup' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {bloodGroups.map((group, index) => (
                                        <Form.Check
                                            key={index}
                                            type='radio'
                                            label={group}
                                            name='bloodGroup'
                                            value={group}
                                            checked={
                                                selectedBloodGroup === group
                                            }
                                            onChange={handleBloodGroupChange}
                                        />
                                    ))}
                                </div>
                            )}
                            {filterType === 'type' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {types.map((type, index) => (
                                        <Form.Check
                                            key={index}
                                            type='radio'
                                            label={
                                                type.charAt(0).toUpperCase() +
                                                type.slice(1)
                                            }
                                            name='type'
                                            value={type}
                                            checked={selectedType === type}
                                            onChange={handleTypeChange}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div> */}
            <table
                className='table'
                style={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                }}
            >
                <thead style={{ borderRadius: '12px' }}>
                    <tr style={{ borderRadius: '12px' }}>
                        <th>Name</th>
                        <th>Phone Number</th>
                        {/* <th>Type</th> */}
                        {/* <th>Blood Group</th> */}
                        {/* <th>Age</th> */}
                    </tr>
                </thead>
                <tbody>
                    {/* {filteredPeople.map((person, index) => ( */}
                    {bloodDonor?.map((person, index) => (
                        <tr key={index} style={{ borderRadius: '12px' }}>
                            <td>{person.ALUMNI_Name}</td>
                            <td>{person.PHONE_NUMBER}</td>
                            {/* <td>{person.type}</td> */}
                            {/* <td>{person.bloodGroup}</td> */}
                            {/* <td>{person.age}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BloodDonor;
