import React, { useState } from 'react';
import './select.css';

const TableTab = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('');

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedFilter('');
    };

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    return (
        <div className='tabs-container'>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '36px',
                }}
            >
                <div
                    className='tabs-header'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <>
                            <div
                                style={{
                                    color: '#000000',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}
                            >
                                {activeTab === 0
                                    ? 'Event Management'
                                    : 'Announcements'}
                            </div>
                            <div
                                style={{
                                    color: '#6C757D',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                }}
                            >
                                {activeTab === 0 ? 'Based on Job Seeking' : ''}
                            </div>
                        </>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            boxShadow: '1px 3px 1px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-header ${
                                    index === activeTab ? 'active' : ''
                                }`}
                                style={{
                                    background:
                                        index === activeTab
                                            ? '#467AFF'
                                            : '#D8D8D8',
                                    color:
                                        index === activeTab
                                            ? '#FFFFFF'
                                            : '#464646',
                                }}
                                onClick={() => handleTabClick(index)}
                            >
                                <div
                                    style={{
                                        padding: '8px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {tab.label}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className='select-container'
                    style={{
                        width: '15%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <select
                        id='filterSelect'
                        value={selectedFilter}
                        onChange={handleFilterChange}
                        style={{ width: '180px' }}
                    >
                        <option value=''>
                            <>All</>
                        </option>
                        {tabs[activeTab].filterOptions.map((option, index) => (
                            <option
                                key={index}
                                value={option.value}
                                style={{ fontSize: '14px' }}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='tab-content'>
                {React.createElement(tabs[activeTab].component, {
                    filterOption: selectedFilter,
                })}
            </div>
        </div>
    );
};

export default TableTab;
