import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { createPortal } from 'react-dom';

function TableLoader() {
    const [el] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(el);
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.removeChild(el);
            document.body.style.overflow = 'auto';
        };
    }, [el]);

    return createPortal(
        <div
            style={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.2)', // semi-transparent black overlay
                zIndex: '10011',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spinner animation='border' />
        </div>,
        el
    );
}

export default TableLoader;
