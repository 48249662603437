import React, { useEffect, useState } from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import StudentForm from './StudentForm';
import viewProps from '../viewProps';
import BulkUpload from '../../components/BulkUpload';
import { useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import errorObjects from './studentErrorObjects';

function StudentDetails() {
    const { user } = useAuthenticationState();

    let getApi = endpoints.alumniDetail.getStudentOnly;

    if (
        [
            // 'DELEGATE',
            // 'DELEGATE + CCH',
            'STUDENTCOUNCIL',
            // 'CLASSREPRESENTATIVE',
            // 'CLASSREP + STUDENT COUNCIL',
        ].includes(user.Role)
    ) {
        getApi = endpoints.alumniDetail.getStudentStudentCouncil;
    }

    const fetchUtils = useAxiosGet(getApi);

    // const fetchUtils = useAxiosGet();
    const { state } = useLocation();

    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const options = { month: 'long', day: 'numeric' };
    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        courses: ({ row }) => {
            if (['STUDENTCOUNCIL'].includes(user.Role)) {
                return row?.original.COURSE_NAME || '';
            }
            return row?.original.batch
                .map((report) => report.COURSE_NAME)
                .join(', ');
        },
        Type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
    };

    let students = [];
    if (!fetchUtils.loading) {
        students = fetchUtils.response.map((data) => {
            if (Array.isArray(data.BATCH_CODE)) {
                data.batch = data.BATCH_CODE;
                data.BATCH_CODE = data.BATCH_CODE[0].BATCH_CODE;
                data.BATCH_NAME=data.batch[0].BATCH_NAME
            }
            return data;
        });
        if (user.Role?.toLowerCase() === 'classrepresentative') {
            students = students.filter((item) => {
                return item.batch[0].BATCH_CODE === user.DELEGATE_BATCH_CODE;
            });
        }
    }
    // console.log(students);
    const today = new Date().getFullYear();
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniDetail}
                Form={StudentForm}
                formSize='lg'
                {...viewProps.StudentDetails}
                cellModifier={cellModifier}
                {...fetchUtils}
                response={students}
                headerExtras={
                    <BulkUpload
                        urlObjects={{
                            templateUrl: endpoints.alumniDetail.bulkUploadTemplate,
                            fileUpload: endpoints.alumniBulkUpload.fileUpload,
                            validateFile: endpoints.alumniBulkUpload.validateFile,
                            initialBulkInsert: endpoints.alumniBulkUpload.initialBulkInsert,
                            bulkUploadConfirm: endpoints.alumniBulkUpload.bulkUploadConfirm,
                        }}
                        filename='Student Details'
                        afterUpload={fetchUtils.reFetch}
                        templateExtension='.xlsx'
                        errorObjects={errorObjects}
                    />
                }
                initialFilterValue={state || ''}
                irremovable
            // immutable
            // insertable = {false}
            />
        </>
    );
}

export default StudentDetails;
