import React, { useState } from 'react';
import {
    Col,
    Nav,
    Row,
    Tab,
    Dropdown,
    DropdownButton,
    ButtonGroup,
    Form,
} from 'react-bootstrap';
import EventCalendar from './EventCalender';
import endpoints from '../../endpoints';
import { useAuthenticationState } from '../../context/Auth.context';
import { useAxiosGet } from '../../hooks/axiosHooks';
import styles from './Batch.css';
import Entrepreneur from './Entrepreneur';
import BloodDonor from './BloodDonor';

function EntrepreneurBloodDonor({ entreprenerList, bloodDonor }) {
    return (
        <Tab.Container defaultActiveKey='Entrepreneur'>
            <Row className='px-2'>
                <Col md='10' className='w-100'>
                    <Nav
                        variant='pills'
                        className={`w-100 text-center shadow-sm ${styles.nav}`}
                    >
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='Entrepreneur'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Entrepreneurs
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='w-50 text-center'>
                            <Nav.Link
                                type='button'
                                className={`p-1 py-1 ${styles.navLink}`}
                                eventKey='BloodDonor'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Blood Donors
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3'>
                <Tab.Pane eventKey='Entrepreneur' title='Entrepreneur'>
                    {entreprenerList?.length > 0 ? (
                        <Entrepreneur entreprenerList={entreprenerList} />
                    ) : (
                        'No Entrepreneur are found'
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey='BloodDonor' title='BloodDonor'>
                {bloodDonor?.length > 0 ? (
                       <BloodDonor bloodDonor={bloodDonor} />
                    ) : (
                        'No Blood Donor are found'
                    )}
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default EntrepreneurBloodDonor;
