import React, { useState } from 'react';
import { Form, Collapse, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoginImage from '../../asset/img/LoginImg.webp';
import { useAuthenticationState } from '../../context/Auth.context';
import { ReactComponent as Koble } from '../../asset/icons/Koble.svg';
import ForgotPassword from './ForgotPassword';
import FirstTimeLogin from './FirstTimeLogin';
import PasswordInput from '../../components/PasswordInput';
import TermsAndPolicy from './TermsAndPolicy';
import Select from 'react-select';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#e8f0fe',
        marginTop: '5px',
        borderRadius: '8px',
        fontSize: '1rem',
        paddingTop: '2px',
        paddingBottom: '2px',
        border: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#000' : '#333',
        padding: '10px',
    }),
};

function Login(props) {
    const [userCredential, setUserCredential] = useState({
        Email: '',
        Password: '',
        INSTITUTION_ID: '',
    });
    const [error, setError] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const { response: institutions, loading } = useAxiosGet(
        endpoints.authentication.institutions
    );

    const institutionOptions = institutions.map((institution) => ({
        value: institution.INSTITUTION_ID,
        label: institution.INSTITUTION_NAME,
    }));
    const { login } = useAuthenticationState();

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();

        setLoggingIn(true);

        login(userCredential)
            .then((user) => {
                setLoggingIn(false);
                if (user.Role?.toLowerCase() === 'superadmin') {
                    setError(true);
                }
                switch (user.Role?.toLowerCase()) {
                // switch ('studentcouncil') {
                    case 'admin':
                    case 'gcm':
                        navigate('/');
                        break;

                    case 'cch':
                        navigate('/cityChapterdashboard');
                        break;

                    // case 'delegate':
                    case 'delegate + cch':
                        navigate('/events');
                        break;
                    case 'institutemember':
                        navigate('/institute-member-dashboard');
                        break;
                    case 'classrepresentative':
                        navigate('/classrepdashboard');
                        break;
                    case 'classrep + student council':
                    case 'studentcouncil':
                        navigate('/student-council-dashboard');

                        break;
                    case 'delegate':
                        navigate('/batchRep-dashboard');
                        // navigate('/')
                        break;

                    default:
                        break;
                }
            })
            .catch(() => {
                setLoggingIn(false);
                setError(true);
            });
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (error) {
            setError(false);
        }

        setUserCredential({
            ...userCredential,
            [name]: value,
        });
    };

    return (
        <div
            style={{
                backgroundImage: 'url(' + LoginImage + ')',
                width: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
            }}
        >
            <span
                className='small text-muted position-absolute w-fit-content'
                style={{ bottom: '2%', right: '2%' }}
            >
                Koble 1.7 <br /> Build 1.7.0.6 - 2024-08-02 09:45
            </span>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundColor: '#00000099',
                }}
            >
                <div
                    // className='py-4'
                    style={{
                        width: '28%',
                        background: '#D9D9D9',
                        borderRadius: '15px',
                        padding: '22px 32px',
                    }}
                >
                    <Koble />

                    <div
                        className='mt-4'
                        style={{
                            marginBottom: '15px',
                            color: '#383C46',
                            lineHeight: '1',
                            fontWeight: '500',
                        }}
                    >
                        Good to see you again
                    </div>
                    <div className='d-flex mb-3'>
                        <span
                            style={{
                                color: 'rgba(0,0,0,0.5)',
                                fontWeight: '500',
                            }}
                        >
                            New to koble ?{' '}
                        </span>
                        <FirstTimeLogin />
                    </div>
                    <Form onSubmit={onLogin} className='px-1'>
                        <label
                            className='small mt-3'
                            style={{ fontWeight: '500' }}
                            htmlFor='institution'
                        >
                            Institution
                        </label>
                        <Select
                            isSearchable
                            isClearable
                            options={institutionOptions}
                            placeholder={
                                loading ? (
                                    <span>Loading institutions...</span>
                                ) : (
                                    'Select institution'
                                )
                            }
                            onChange={(selected, action) => {
                                if (action.action === 'clear') {
                                    setUserCredential({
                                        ...userCredential,
                                        INSTITUTION_ID: '',
                                    });
                                    return;
                                }
                                setUserCredential({
                                    ...userCredential,
                                    INSTITUTION_ID: selected.value,
                                });
                                if (error) {
                                    setError(false);
                                }
                            }}
                            styles={customStyles}
                            isLoading={loading}
                            required
                        />
                        <label
                            className='small mt-2'
                            style={{ fontWeight: '500' }}
                            htmlFor='username'
                        >
                            User Name
                        </label>
                        <Form.Control
                            className='clearFocusDecorate py-2 mt-1 mb-2'
                            name='Email'
                            style={{
                                background: '#F2F2F2',
                                borderRadius: '8px',
                                paddingTop: '12px',
                                paddingBottom: '12px',
                            }}
                            id='username'
                            value={userCredential.Email}
                            placeholder='Enter your Username'
                            required
                            onChange={onChangeHandler}
                        />

                        <label
                            className='small mb-1'
                            style={{ fontWeight: '500' }}
                            htmlFor='password'
                        >
                            Password
                        </label>
                        <PasswordInput
                            className='clearFocusDecorate py-2'
                            name='Password'
                            id='password'
                            value={userCredential.Password}
                            placeholder='Enter your Password'
                            required
                            onChange={onChangeHandler}
                            autoComplete='off'
                        />
                        <div style={{ color: 'red', fontSize: '12px' }}>
                            {error}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <ForgotPassword />
                        </div>
                        <Collapse in={error}>
                            <div
                                className='text-danger bg-white p-1 mt-2 text-center rounded'
                                style={{ fontSize: '12px' }}
                            >
                                * Invalid Credentials
                            </div>
                        </Collapse>

                        <TermsAndPolicy />

                        <div className='d-grid mt-4'>
                            <Button
                                type='submit'
                                className='fw-bold text-white py-2 border-0 rounded-smooth'
                                style={{
                                    background: '#396FFA',
                                    fontSize: '18px',
                                }}
                                disabled={loggingIn}
                            >
                                {loggingIn ? (
                                    <Spinner size='sm' animation='border' />
                                ) : (
                                    'Login'
                                )}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Login;
