import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import MarkAsSafeTable from './MarkAsSafeTable';
import { ReactComponent as Safe } from '../../asset/icons/MarkSafe.svg';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';

function MarkAsSafe({ data }) {
    return (
        <Tab.Container defaultActiveKey='SafeEvent'>
            <Row className='px-2'>
                <Col className='d-flex'>
                    <Safe />
                    <Col className='px-2'>
                        <div className='small h6 text-dark'>
                            Mark As Safe Events
                        </div>

                        <div
                            className='text-muted'
                            style={{ fontSize: '.75em' }}
                        >
                            Based on Batch
                        </div>
                    </Col>
                </Col>
                <Col md='5'>
                    <Nav variant='pills'>
                        <Nav.Item className=' ms-auto'>
                            <Nav.Link
                                type='button'
                                className='px-4 py-0'
                                eventKey='SafeEvent'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Filter <Filter />
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='SafeEvent' title='SafeEvent'>
                    {/* <div className='p-5 '>
                        No data based on Mark as safe has been provided
                    </div> */}
                    <MarkAsSafeTable />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default MarkAsSafe;
