import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { ReactComponent as Event } from '../../asset/icons/EventLogo.svg';
import EventCalender from './EventCalender';
import Announcement from './Announcement';

function EventAnnouncement({ data }) {
    const { industry, organization } = data;

    const alumniPerIndustry = {
        labels: industry?.formattedData?.labels,
        datasets: industry?.formattedData?.datasets,
    };

    let labelOrg = [];
    let counts = [];

    organization.forEach((obj) => {
        labelOrg.push(obj?.Organization_Name);
        counts.push(obj?.COUNT_ALUMNI);
    });

    const alumniPerOrg = {
        labels: labelOrg,
        datasets: [
            {
                data: counts,
            },
        ],
    };

    return (
        <Tab.Container defaultActiveKey='Event'>
            <Row className='px-2'>
                <Col className='d-flex'>
                    <Event />
                    <Col className='px-2'>
                        <Tab.Content>
                            <Tab.Pane eventKey='Event' title='Event'>
                                <div className='small h6 text-dark'>
                                    Event Details
                                </div>
                                <div
                                    className='text-muted'
                                    style={{ fontSize: '.75em' }}
                                >
                                    Based on Batch | Total 12 Events
                                </div>
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey='Announcements'
                                title='Announcements'
                            >
                                <div className='small h6 text-dark'>
                                    Announcements Details
                                </div>
                                <div
                                    className='text-muted'
                                    style={{ fontSize: '.75em' }}
                                >
                                    Based on Batch | Total 12 Announcements
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Col>
                <Col md='5'>
                    <Nav variant='pills'>
                        <Nav.Item className=' ms-auto'>
                            <Nav.Link
                                type='button'
                                className='p-3 py-1'
                                eventKey='Event'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Events (12)
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                type='button'
                                className='p-3 py-1'
                                eventKey='Announcements'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Announcements (8)
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='Event' title='Event'>
                    {alumniPerIndustry?.labels?.length === 0 ||
                    alumniPerIndustry?.labels?.length === undefined ? (
                        <div className='p-5 '>
                            No job domain data based on industry has been
                            provided
                        </div>
                    ) : (
                        <EventCalender />
                    )}
                </Tab.Pane>
                <Tab.Pane eventKey='Announcements' title='Announcements'>
                    {alumniPerOrg?.labels?.length === 0 ||
                    alumniPerOrg?.labels?.length === undefined ? (
                        <div className='p-5 '>
                            No job domain based on organization has been
                            provided
                        </div>
                    ) : (
                        <Announcement />
                    )}
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default EventAnnouncement;
