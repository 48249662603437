import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import endpoints from '../../endpoints';
import { useAxiosGet } from '../../hooks/axiosHooks';
import { useLocation } from 'react-router-dom';

function CityChapterForm({ endpoint, onCancel, onAfterSubmit, showAlert }) {
    const { response: alumni, loading } = useAxiosGet(
        endpoints.alumniDetail.get
    );
    const aluminis = alumni.filter((alumni) => alumni.Type == 1);
    const { state: cityId } = useLocation();

    const submitHandler = (values, { setSubmitting }) => {
        values.ALUMNI_CHAPTERTAG_ID =
            values.ALUMNI_CHAPTERTAG_ID.CityChapter_ID;
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = {
        ALUMNI_CHAPTERTAG_ID: cityId,
        ALUMNI_BATCH_ADM_CODE_ARRAY: [],
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                ALUMNI_BATCH_ADM_CODE_ARRAY: Yup.array()
                    .min(1, 'Please select alumni')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control="select-advanced"
                        required
                        name="ALUMNI_BATCH_ADM_CODE_ARRAY"
                        label="User"
                        options={aluminis}
                        isMulti
                        optionValue="ALUMNI_BATCH_ADM_CODE"
                        optionLabel="ALUMNI_Name"
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CityChapterForm);
